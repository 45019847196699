@keyframes toggleTransparency {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.focal-card-hover {
  .images_container {
    transition: all 200ms;
  }
  &:hover {
    .images_container {
      box-shadow: 5px 10px 20px rgba(126, 126, 133, 0.5);
      scale: 1.009;
    }
  }
}

.focal-hover-shadow {
  transition: box-shadow 200ms;
  &:hover {
    box-shadow: 5px 10px 20px rgba(126, 126, 133, 0.4);
  }
}
