.focal {
  .player-widths {
    // width: 98%;
    // max-width: $breakpoint_1400;
    width: 100%;
  }
  .container-webplayer {
    @extend .player-widths;
    margin: 0 auto;
    @media only screen and (max-width: $breakpoint_658) {
      max-width: 100%;
    }
  }
  .mini-player {
    padding: 0;
    height: 100px;
    background: transparent;
    .slider-box {
      width: 50px;
    }
    .duration {
      display: flex;
      width: 100%;
      justify-content: space-between;
      font-size: 11px;
      padding: 3px 0px;
      color: $focalmidgrey;
    }
    .volume-container {
      background-color: transparent;
    }
    background-color: transparent;
    .rc-slider {
      padding-top: 7px;
      padding-bottom: 7px;
      top: unset;
      cursor: pointer;
    }
    .rc-slider-track,
    .rc-slider-rail {
      height: 2px;
    }
    .rc-slider-track,
    .rc-slider-rail,
    .iconVolume {
      cursor: pointer;
    }

    .rc-slider-handle {
      height: 8px;
      width: 2px;
      border-radius: 1px;
      border: 0;
      height: 10px;
      width: 2px;
      border-radius: 1px;
      top: 3px;
    }
    .rc-slider-track,
    .rc-slider-handle {
      background-color: $focalmidgrey;
      border-color: $focalmidgrey;
    }
    .iconPrev,
    .iconNext {
      cursor: pointer;
    }
    .box-icons {
      margin-right: 0px;
      svg {
        width: 40px;
        height: 40px;
      }
      .iconPrev,
      .iconNext {
        svg {
          width: 20px;
          height: 20px;
        }
      }
      > div {
        cursor: pointer;
      }
      img {
        transition: all 200ms;
      }
      img:hover {
        filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.4));
      }
      img:active {
        filter: none;
      }
    }
  }
  .focal .song-info {
    font-size: 14px;
  }
  .docked-player {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 10px 0px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    transition: box-shadow 200ms;
    box-shadow: 0px 0px 15px rgba(126, 126, 133, 0.3);
    background-color: white;
    font-size: 13px;
    border: 1px solid $focalgrey;

    &.desktop {
      .theme-slider {
        .rc-slider-handle {
          height: 10px;
          margin-top: 0px;
        }
      }
      & > .content {
        width: 100%;
      }
      .song-info {
        margin-top: 10px;
      }
    }
    &.mobile {
      .iconPausePlay {
        margin-left: 8px;
        margin-right: 8px;
      }
      .top .left {
        flex: unset;
      }
      .top {
        .theme-slider {
          .rc-slider {
            cursor: pointer;
          }
          .rc-slider,
          .rc-slider-handle {
            display: flex;
            align-items: center;
          }
          .rc-slider-handle {
            margin: auto;
          }
        }
      }
      .bottom {
        display: flex;
        position: relative;
        justify-content: space-between;
      }
    }

    .player-right-tab {
      position: absolute;
      right: 10px;
      top: -20px;
      background: $white;
      border-radius: 25px;
      padding: 5px;
      box-shadow: 0px -6px 5px rgba(126, 126, 133, 0.1);
      cursor: pointer;
      img,
      svg {
        transition: all 200ms;
        height: 30px;
        width: auto;
        &:hover,
        &:active {
          filter: brightness(0.9);
        }
      }
      &.rotate {
        img,
        svg {
          height: 30px;
          width: auto;
          transform: rotate(180deg);
        }
      }
    }
    .song-info__row {
      flex-direction: column;
      align-items: flex-start;
      font-size: 12px;
      margin-bottom: 0px;
      overflow: hidden;
      .song-info__composer,
      .song-song-info__name,
      .song-info__performances {
        line-height: 15px;
        margin-bottom: 0px;
        display: block;
      }
    }
    .top {
      flex: 2;
    }
    .bottom {
      flex: 1;
      margin-top: 5px;
    }
    .top,
    .bottom {
      display: flex;
      flex-direction: row;
      align-items: center;
      .left,
      .right {
        display: flex;
        flex-direction: row;
      }
      .left {
        flex: 4;
        overflow: hidden;
      }
      .right {
        flex: 5;
        .mini-player__controls-right {
          justify-content: space-evenly;
          width: 100%;
        }
      }
    }
    .mini-image-composer {
      height: 50px;
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 5px;
      img {
        border-radius: 100%;
      }
    }

    .theme-slider {
      .rc-slider-handle {
        border-color: $focalgrey;
      }
      .rc-slider-track,
      .rc-slider-handle {
        background-color: $focalmidgrey;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05) inset;
      }
      .rc-slider-rail,
      .rc-slider-track,
      .rc-slider-handle {
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05) inset;

        cursor: pointer;
      }
    }
  }
  .expanded-player {
    .details-content-tabs {
      .tab-indicator {
        content: "";
        display: block;
        position: relative;
        bottom: 0;
        height: 2px;
        width: calc(100% / 2);
        background: #18181a;
        margin-bottom: -2px;
        background: $focalblack;
        left: 0;
        transition: all 200ms;
        z-index: 2;
      }
      .tabs {
        display: flex;
        flex-direction: row;
        align-items: center;
        box-shadow: inset 0px -1px 0px #dcdfe4;
        justify-content: center;
        max-width: 1120px;
        margin: 0px auto;
        user-select: none;
        overflow-x: auto;
        position: relative;
        z-index: 1;
        @media only screen and (max-width: $breakpoint_658) {
          justify-content: unset;
        }
        &:visited {
          text-decoration: none;
        }
  
        .tab {
          cursor: pointer;
          &.active {
            color: $black;
            opacity: 1;
          }
          &.disabled {
            opacity: 0.2;
            cursor: not-allowed;
          }
          width: 100%;
          opacity: 0.4;
          > div {
            display: flex;
            flex-direction: column;
            font-size: 15px;
            justify-content: center;
            align-items: center;
            padding: 15px;
            white-space: nowrap;
            img {
              margin-bottom: 10px;
            }
            @media only screen and (max-width: $breakpoint_658) {
              padding: 5px;
              img {
                margin-bottom: 0px;
              }
              font-size: 13px;              
            }
            @media only screen and (max-width: $breakpoint_1024) and (orientation: landscape) {
              padding: 5px;
            }
          }
        }
  
        a,
        div {
          text-decoration: none;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          color: $focalblack;
          position: relative;
          // -webkit-animation-name: fadeIn;
          // animation-name: fadeIn;
          // animation: fadeIn 200ms ease both;
          // animation-iteration-count: 1;
  
          @media only screen and (max-width: $breakpoint_880) {
            font-size: 18px;
            line-height: 22px;
          }
        }
      }
  
      .showTab {
        display: block;
        -webkit-animation-name: slideIn;
        animation-name: slideInRight;
        animation-timing-function: ease;
        animation-iteration-count: 1;
        animation-duration: 200ms;
        &.from-left {
          animation-name: slideInLeft;
          animation-timing-function: ease;
          animation-iteration-count: 1;
          animation-duration: 200ms;
        }
      }
  
      .hideTab {
        display: none;
      }
  
      .read-tab-pane {
        &.about-album {
          max-height: unset;
          p {
            line-height: 20px;
          }
        }
      }
    }

    // @extend .player-widths;
    position: fixed;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
    border-radius: 5px;
    background-color: $white;
    height: calc(100vh - 80px);
    padding-bottom: 100px;
    transform: translateY(100vh);
    transition: all 400ms;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    box-shadow: 5px 10px 20px rgba(126, 126, 133, 0.5);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    &.show {
      transform: translateY(80px);
    }
    &.desktop {
      .image-container {
        margin-right: 30px;
        @media only screen and (max-width: $breakpoint_768) {
          margin-right: 15px;
        }
      }
    }
    &.mobile {
      width: 98%;
      height: 100%;
      .image-container {
        margin-right: 0 auto;
      }
    }

    .top,
    .bottom {
      padding: 15px;
      width: 100%;
    }
    .top {
      height: 80%;
      max-height: 500px;
      .container {
        height: 100%;
        display: flex;
        width: 80%;
        margin: 0 auto;
        max-width: 800px;
      }
      @media only screen and (max-width: $breakpoint_768) {
        .container {
          width: 95%;
        }
      }
      @media only screen and (max-width: $breakpoint_1024) and (orientation: landscape) {
        height: 90%;
      }
      .overlayResp {
        filter: blur(3px);
      }
      .image-container {
        height: 100%;
        width: max-content;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        aspect-ratio: 1/1;
        @media only screen and (max-width: $breakpoint_768) {
          height: 80%;
          margin-top: auto;
          margin-bottom: auto;
          // width: 50%;
        }
        img {
          height: 100%;
          width: auto;
          aspect-ratio: 1/1;
        }
        .image-blurred-bg-container {
          .blurred-bg {
            filter: blur(50px);
            -webkit-filter: blur(50px);
            object-fit: cover;
            opacity: 1;
          }
          .track-image {
            position: absolute;
            border-radius: 50%;
            height: 80%;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            bottom: 0;
          }
        }
      }
    }
    .bottom {
      flex-grow: 1;
      display: flex;
      align-items: center;
      .container {
        width: 80%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        .action-btn {
          margin-right: 10px;
        }
      }
    }
  }
  .settings-options-wrapper {
    position: absolute;
    width: auto;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #797980;
    box-shadow: 0px 0px 8px rgba(20, 17, 64, 0.5);
    border-radius: 4px;
    cursor: pointer;
    z-index: 1;
    right: 0;
    left: -190px;

    &:after {
      right: 10px;
    }
    &.bottom-menu {
      top: 38px;
      right: 0;
      &:after {
        right: 10px;
        top: -5px;
        border-bottom: 6px solid #fff;
        border-top: unset;
      }
    }
  }

  .track-info-container {
    display: flex;
    align-items: center;
    font-size: 13px;
    flex: 1;
    @media only screen and (max-width: $breakpoint_1024) and (orientation: landscape) {
      overflow-y: auto;
    }

    .track-info {
      .title {
        font-size: 30px;
      }
      p,
      div {
        line-height: 1.2;
        & > span {
          color: $focalblack;
          &:first-child {
            color: $focalmidgrey;
          }
          &.color-black {
            color: $focalblack;
          }
        }
        a {
          color: $focalblack;
          &:visited {
            color: $focalblack;
          }
        }
      }
    }
    .playing-from-link {
      line-height: 1.5;
    }
    a,
    .has-link {
      text-decoration: underline;
      text-underline-offset: 2px;
      &::after {
        -webkit-mask: url("../assets/caretRight.svg") no-repeat 50% 50%;
        mask: url("../assets/caretRight.svg") no-repeat 50% 50%;
        -webkit-mask-size: 5px;
        mask-size: 5px;
        mask-position: 5px 1px;
        transition: all 200ms;
        display: inline-block;
        width: 10px;
        height: 10px;
        padding-right: 10px;
        content: "";
        background-repeat: no-repeat;
        background-position: 5px bottom;
        background-color: currentColor;
      }
      &:hover {
        &::after {
          mask-position: 10px 1px;
          color: inherit;
          stroke: inherit;
        }
      }
    }
  }
}
.focal {
  .mobile-track-info {
    .top {
      height: 75%;
      .container {
        flex-direction: column;

        .image-container {
          height: auto;
          width: 100%;
        }
        .song-info {
          text-align: center;
          margin-top: 10px;
        }
        .song-info__row {
          align-items: center;
          .song-info__composer {
            margin-bottom: 8px;
            margin-right: 0px;
          }
          .song-info__performances {
            margin-right: 0;
            margin-top: 5px;
            display: unset;
            width: 100%;
            .artist {
              height: unset;
              line-height: 1.15;
              div {
                display: inline;
              }
            }
          }
        }
      }
      .image-container {
        margin: 0 auto;
      }
    }
    .bottom {
      .track-info {
        width: 100%;
      }
    }
  }
}
.focal {
  .expanded-player {
    .showTab,
    .tabContainer,
    .details-content-tabs,
    .desktop-track-info,
    .mobile-track-info {
      height: 100%;
      width: 100%;
      position: relative;
    }
    @media only screen and (max-width: $breakpoint_1024) and (orientation: landscape) {
      .desktop-track-info {
        .track-info-container {
          .track-info {
            p,
            div {
              &.pb-10 {
                padding-bottom: 3px;
              }
              &.mt-5 {
                margin-top: 0;
              }
            }
          }
        }
      }
    }
    .desktop-track-info,
    .mobile-track-info {
      @media only screen and (min-width: $breakpoint_1400) {
        .bottom {
          flex-grow: unset;
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .tab {
      font-weight: bold;
      text-transform: uppercase;
    }

    .desktop-play-queue {
      padding: 10px;
      padding-bottom: 10px;
      height: 100%;
      padding-bottom: 100px;
      overflow-y: auto;
      @extend .thin-scrollbars;
    }
    .track-list {
      padding: 0;
    }
  }
}
.focal.bottom-spacer {
  padding-bottom: 200px;
}

.focal {
  .mobile-track-credits-popup {
    position: fixed;
    width: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 0;
    z-index: 2;
    border-radius: 5px;
    background-color: $white;
    height: 100vh;
    padding-bottom: 100px;
    transform: translateY(100vh);
    transition: all 400ms;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    box-shadow: 5px 10px 20px rgba(126, 126, 133, 0.5);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    &.show {
      transform: translateY(0px);
    }
    .header {
      display: flex;
      align-items: center;
      width: 100%;
      &-title {
        left: 0;
        right: 0;
        position: absolute;
        text-align: center;
      }
      .nav-button {
        align-self: flex-start;
        margin-left: 0;
      }
    }
  }
}
