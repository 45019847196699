.focal {
  .dynamic-page {
    .focal-home__widget-wrapper {
      display: flex;
      flex-direction: column;
      gap: 45px;
      .divider-widget {
        max-width: 930px;
        border-width: 0.05rem 0 0 0;
        width: 100%;
        margin: auto;
      }
    }
  }
  .focal-home {
    .focal-home__widget-wrapper {
      display: flex;
      flex-direction: column;
      gap: 45px;
      .divider-widget {
        max-width: 930px;
        width:100%;
        border-width: 0.05rem 0 0 0;
        margin: auto;
      }
    }
  }
  .details-page,
  .focal-home {
    .welcome-banner {
      .banner-wrapper {
        > .description {
          display: none;
        }
      }

      .media-type-outer {
        max-width: 1120px;
        margin: 5px auto 0px auto;
        font-weight: 500;
        font-size: 13px;

        @media screen and (max-width: $breakpoint_1200) {
          padding-left: 20px;
        }
      }

      .media-type-inner {
        margin: 5px auto 10px auto;
        font-weight: 500;
        font-size: 13px;

        @media screen and (max-width: $breakpoint_1200) {
          margin: 15px auto 10px 0px;
        }
      }

      .banner-wrapper {
        flex-direction: row;

        &.album {
          .left-section {
            width: 50%;
          }

          .right-section {
            width: 50%;
          }
        }
      }

      .left-section,
      .right-section {
        flex: 1;
        mask-image: unset;
        -webkit-mask-image: unset;
      }

      .left-section {
        flex: 2;

        .title {
          margin-bottom: 10px;
          font-weight: 500;
          font-size: 13px;
        }

        .description {
          text-align: justify;
        }
      }

      .right-section {
        display: flex;

        .banner-image {
          height: max-content;
          object-fit: cover;
          width: 100%;
          aspect-ratio: 1/1;
          margin-bottom: auto;
          margin-top: auto;
        }
        .personal-playlist-trackImages {
          width: 100%;
          max-width: 360px;
          display: flex;
          flex-wrap: wrap;

          .track-item {
            width: 50%;
            object-fit: cover;
          }
        }
        .focal-play-icon {
          display: none;
        }
      }
    }
  }

  .focal-home {
    .welcome-banner {
      .media-type-inner {
        margin: 5px auto 10px auto;
        font-weight: 500;
        font-size: 13px;

        @media screen and (max-width: $breakpoint_1200) {
          margin: 15px auto 10px 0px;
        }
      }

      @media screen and (max-width: $breakpoint_658) {
        .divider-component {
          display: none;
        }

        .banner-wrapper {
          .left-section {
            > .description {
              display: none;
            }
          }

          > .description {
            font-size: 15px;
            font-weight: 500;
            line-height: 23px;
            padding: 10px 40px 20px 40px;
            text-align: justify;
            display: block;
          }
        }
      }
    }
  }

  .details-page {
    .welcome-banner {
      .banner-wrapper {
        flex-direction: row;
        background-color: "red";

        &.video {
          flex-direction: column-reverse;

          .left-section {
            width: 100%;
          }

          .right-section {
            width: 100%;
          }

          .expand {
            margin-left: 0px;
          }

          @media screen and (max-width: $breakpoint_658) {
            .right-section {
              order: 3;
            }

            .left-section {
              order: 2;
            }

            .description {
              order: 1;
            }
          }

          .banner-video {
            position: relative;
            padding-bottom: 56.25%;
            padding-top: 25px;
            height: 0;

            @media screen and (max-width: $breakpoint_1200) {
              margin-left: 20px;
              margin-right: 20px;
            }

            iframe {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: $breakpoint_658) {
    .focal-home {
      .focal-home__widget-wrapper {
        gap: 30px;
      }
    }
    .dynamic-page {
      .focal-home__widget-wrapper {
        gap: 30px;
      }
    }
    .details-page {
      .welcome-banner {
        .banner-wrapper {
          flex-direction: column;

          > .description {
            font-size: 15px;
            font-weight: 500;
            line-height: 23px;
            padding: 10px 40px 20px 40px;
            text-align: justify;
            display: block;
          }

          &.album {
            .left-section {
              width: 100%;
            }

            .right-section {
              width: 100%;
            }
          }

          .left-section {
            text-align: center;
            width: 100%;
            padding-left: 40px;
            padding-right: 40px;
            margin: 0;
            // order: 2;

            .buttons-wrapper {
              display: none;
            }

            .text-header {
              font-size: 23px;
              font-weight: 700;
              line-height: 29px;
            }

            .description {
              display: none;
            }
          }
        }

        .right-section {
          width: 100%;
          margin-bottom: 10px;
          position: relative;

          &.hide-on-mobile {
            display: none;
          }

          .banner-image {
            max-height: unset;
            width: 65%;
            margin: auto;
          }

          .banner-video {
            width: 100%;
          }

          .focal-play-icon {
            position: absolute;
            left: 0%;
            bottom: 6%;
            display: flex;
            padding-top: 200px;

            svg {
              height: 40px;
            }

            .play-icon-copy {
              height: 40px;
              max-width: 300px;
              margin-left: -20px;
              padding-right: 30px;
              padding-left: 23px;
              padding-top: 16px;
              padding-bottom: 16px;
            }
          }
        }
      }
    }

    .focal-home {
      .welcome-banner {
        .banner-wrapper {
          flex-direction: column;

          &.album {
            .left-section {
              width: 100%;
            }

            .right-section {
              width: 100%;
            }
          }

          .left-section {
            text-align: left;
            width: 100%;
            padding-left: 40px;
            padding-right: 40px;
            margin: 0;
            // order: 2;

            .buttons-wrapper {
              display: none;
            }

            .text-header {
              font-size: 23px;
              font-weight: 700;
              line-height: 29px;
            }

            .description {
              font-size: 15px;
              font-weight: 500;
              line-height: 23px;
              padding-bottom: 20px;
            }
          }
        }

        .right-section {
          width: 100%;
          margin-bottom: 10px;
          position: relative;
          margin-left: 40px;
          margin-top: -5px;

          &.hide-on-mobile {
            display: none;
          }

          .banner-image {
            max-height: unset;
            width: 55%;
          }

          .banner-video {
            width: 100%;
          }

          .focal-play-icon {
            position: absolute;
            left: 0%;
            bottom: 6%;
            display: flex;
            width: 55%;
            padding-top: 150px;

            svg {
              height: 40px;
            }

            .play-icon-copy {
              height: 40px;
              max-width: 300px;
              margin-left: -20px;
              padding-right: 30px;
              padding-left: 23px;
              padding-top: 16px;
              padding-bottom: 16px;
            }
          }
        }
      }
    }
  }
}
