.focal {
    .Modal {
        position: absolute;
        top: 40px;
        left: 40px;
        right: 40px;
        bottom: 40px;
        overflow: 'auto',
    }

    .egClass:focus {
        outline: none !important;
    }

    .main-container {
        display: inline;
    }

    .library-parent-container {
        padding: 0px 20px 0px 20px;
    }

    .bold {
        color: $focalblack;
        font-family: $poppins;
        font-size: 19px;
        font-style: normal;
        font-weight: 800;
        line-height: 140.625%;
    }

    .rows .row {
        display: inline-block;
    }

    .side-menu-container {
        background-color: #fff !important;
    }

    .menu-item-container {
        width: 219px;
        height: 38px;
        align-items: flex-start;
    }

    .menu-item-container-mobile {
        height: 45px;
    }

    .content-container {
        max-height: 765px;
        overflow: auto;
    }

    .content-container-mobile {
        max-height: 765px;
        overflow: auto;
        width: 100%;
        height: 100%;
        padding: 0px 20px 0px 20px;
    }

    .menu-toggle {
        color: $focalblack;
    }

    .menu_icon {
        width: 18px;
        height: 18px;
        flex-shrink: 0;
    }

    .nav-item {
        // display: flex;
        max-width: 245px;
        padding: 8px 0px;
        align-items: center;
        gap: 10px;
    }

    .menu-text {
        color: $focalmidgrey;
        text-align: start;
        font-family: $poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .mobile-menu-text {
        font-size: 15px;
    }

    .icon-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 10px 0px 10px 0px;
        gap: 10px;
        padding-left: 10px;
        flex-shrink: 0;
        align-self: stretch;
        cursor: pointer;
    }

    .preicon-container {
        justify-content: flex-start;
        align-self: flex-start;
    }

    .text-close {
        color: $focalmidgrey;
        font-family: $poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
        align-self: center;
    }

    .div-with-icons {
        display: flex;
        padding: 0px 0px;
        align-items: center;
        gap: 10px;
        margin: 0px 15px 0px 15px;
        background-color: $white;
        cursor: pointer;
    }

    .leftIcon {
        width: 18px;
        height: 18px;
    }

    .rightIcon {
        width: 3px;
        height: 6px;
    }

    .text {
        color: $focalmidgrey;
        font-family: $poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .empty-container {
        padding: 10px 40px 0px 20px;
        width: 999px;

        @media only screen and (max-width: 868px) {
            width: 399px;
        }
    }

    .selected-text {
        color: $focalblack;
        font-family: $poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .selected-text-mobile {
        font-size: 15px;
    }

    .square-playlist {
        max-height: 200px;
        height: 150px;
        border: 1px solid #F0F0F2;
        overflow: hidden;
        display: flex;
        margin-bottom: 15px;
        justify-content: center;
        align-items: center;
    }

    .menu-header {
        color: $focalblack;
        font-family: $poppins;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 140.625%;
        text-transform: uppercase;
    }

    .small-media-card-container {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .focal-library-wrapper {
        .lib-media-card-container {
            display: grid;
            margin-bottom: 80px;
            grid-template-columns: repeat(5, minmax(0, 1fr));
            gap: 10px 1%;

            // Workaround for now. We will probably fix this once we improve our code hierarchy.
            padding-bottom: 100px;

            @media only screen and (max-width: 1268px) {
                grid-template-columns: repeat(4, minmax(0, 1fr));
            }

            @media only screen and (max-width: 1080px) {
                grid-template-columns: repeat(3, minmax(0, 1fr));
            }

            @media only screen and (max-width: 868px) {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            @media only screen and (max-width: 658px) {
                grid-template-columns: repeat(1, minmax(0, 1fr));
            }
        }
    }
}