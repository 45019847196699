.square-button {
  width: 100%;

  .button-black {
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    animation: fadeIn 200ms ease both;
    animation-iteration-count: 1;
  }

  .loading {
    animation: toggleTransparency 2s ease-in-out infinite;
  }
}

.save-collection-button {
  background-color: white;
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;

  &:hover {
    cursor: pointer;
    background-color: $focalblack;
    color: white;
    opacity: 90%;
  }

  &.saved {
    border: 0px;
  }
}

.rounded-button {
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 100%;
  border-radius: 50px;
}

.square-button,
.rounded-button {
  border: 1px solid;

  &.muted,
  .muted {
    opacity: 0.8;
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  &:hover {
    cursor: pointer;
  }

  &:active {
    opacity: 0.1;
  }
}

.button-black {
  background-color: $focalblack;
  color: $white;
  border-radius: 25px;
  font-size: $size_14;
  transition: all 200ms;
  cursor: pointer;

  @extend .focal-hover-shadow;

  &.square {
    border-radius: 5px;
  }

  &:active {
    opacity: 0.5;
  }
}

.button-grey {
  background-color: $focalgrey;
  color: $focaldarkgrey;
  border-radius: 25px;
  font-size: $size_14;
  transition: all 200ms;
  cursor: pointer;

  @extend .focal-hover-shadow;
}

.login-signup-wrapper {
  .login-signup-wrapper__container{
    display: flex;
    gap: 10px;
    overflow: hidden;
  }
  .login-signup-wrapper__login, .login-signup-wrapper__signup{
    padding: 10px 15px;
    font-size: 0.8rem;
    border-radius: 20px;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  @media only screen and (max-width: $breakpoint_658) {
    .login-signup-wrapper__container{
      display:flex;
      flex-direction: column;
      gap: 15px;
      max-height: auto;
    }
    .login-signup-wrapper__signup,.login-signup-wrapper__login{
      text-align: center;
      font-size: 0.7rem;
    }
  }
  .button {
    transition: all 200ms;
    @extend .focal-hover-shadow;
    cursor: pointer;
    &:active {
      filter: brightness(60%);
    }
  }
}

.focal {
  .logo-wrapper {
    height: 100%;

    img {
      max-width: 96px;
      width: 96px;
      height: auto;
      object-fit: contain;
      max-height: 68px;

      @media only screen and (max-width: $breakpoint_658) {
        max-width: 100px;
      }
    }
  }
}

.focal {
  .media-card {
    padding: 10px;
    background-color: transparent;

    .images_container {
      max-height: 150px;
    }

    @extend .focal-card-hover;

    &.active {
      .images_container {
        max-height: 300px;
        height: 300px;
      }

      .title {
        font-size: 19px;

        .title-text {
          .truncate {

            &.tiny,
            &.short {
              line-clamp: unset;
              -webkit-line-clamp: unset;
            }
          }
        }
      }

      .description {
        line-height: 26px;
        font-size: 13px;

        .truncate {

          &.tiny,
          &.short {
            line-clamp: 6;
            -webkit-line-clamp: 6;
          }
        }
      }
    }

    .title {
      font-size: 14px;
      line-height: 1.4;

      .title-text {
        &.truncate {
          &.tiny {
            line-clamp: 3;
            -webkit-line-clamp: 3;
          }
        }
      }
    }

    &:hover {
      .images_container {
        .children-1 {
          &.default {
            img {
              scale: unset;
            }
          }
        }
      }
    }
  }

  .AppearsOnBox {
    padding: 0;
  }

  .default-media-card-container {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: 10px 1%;

    &.slider-component {
      .slick-slide>div>div {
        padding-right: 1%;
      }
    }

    @media only screen and (max-width: $breakpoint_658) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  .divider-component {
    .border-grey {
      border-color: $focalgrey;
    }
  }

  .default-page-section-container {
    .header-section {
      margin-bottom: 0;
    }
  }

  .slick-slide {
    &>div {
      margin-bottom: 1%;

      &>div {
        margin-right: 1%;
      }
    }
  }
}

.pill-button {
  display: flex;
  align-items: center;
  margin-left: 10px;
  transition: all 200ms;
  font-size: 14px;
  overflow: hidden;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    filter: brightness(90%);
  }

  .label {
    padding: 5px;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  .nav-icon {
    padding: 5px;

    svg {
      max-height: 10px;
    }
  }

  .see-all-icon {
    padding: 5px;

    svg {
      max-height: 14px;
    }
  }

  &.nav-button {
    border-radius: 10px;
    color: $focalmidgrey;
    background: $focalgrey;
    border: 1px solid $focalgrey;
  }

  &.see-all-button {
    background: $white;

    .label {
      border-left: 1.5px solid $focalblack;
    }

    border-radius: 10px;
    color: $focalblack;
    border: 1.5px solid $focalblack;
  }
}

.focal {
  .track-list {
    font-size: 14px;
    padding: 32px 0;

    .track-icon {
      max-width: 50px;
      min-width: 50px;

      @media only screen and (max-width: $breakpoint_658) {
        max-width: 65px;
        min-width: 65px;
      }

      margin-right: 10px;
      border-radius: 5px;
      overflow: hidden;
      position: relative;

      .focal-play-icon {
        position: absolute;
        bottom: -1px;
        left: -1px;
        transform: scale(0.7);
      }

      img {
        width: 100%;
      }
    }

    .track-item {
      cursor: pointer;
      padding: 15px 10px;
      border-radius: 0px;

      &.active {
        .track-title {
          color: #0092ed;
        }
      }

      @media only screen and (max-width: $breakpoint_658) {
        padding: 10px;
      }

      border-bottom: 1px solid $focalgrey;
    }
  }
}

.focal {
  .image-gallery {
    width: 100%;

    .image-gallery-image {
      height: 60vh;
      cursor: pointer;
      padding-bottom: 30px;
    }

    .image-gallery-thumbnail {
      .image-gallery-thumbnail-inner {
        border: 2px;
        border-style: solid;
        border-color: transparent;

        .image-gallery-thumbnail-image {
          vertical-align: middle;
          height: 50px;
          line-height: 0;
          width: auto;
          margin: 0 auto;
          max-width: 100%;
        }
      }

      &.active,
      &:focus,
      &:hover {
        border: none;
      }
    }

    &.fullscreen-modal {
      .image-gallery-image {
        height: 80vh;
      }
    }

    .fullscreen-btn {
      position: absolute;
      right: 0;
      top: 0;
      padding: 10px;
      border-width: 1px;
      border-radius: 100px;
    }
  }
}

.focal {
  .html-widget {
    display: flex;
    justify-content: center;
  }
}

.focal {
  .button-filter-container {
    transition: all 200ms;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    .loading-icon {
      svg {
        max-height: 30px;
      }
    }

    .button-filter {
      &.no-border {
        border-color: transparent !important;

        .label {
          border-color: transparent !important;
        }
      }

      border: 1.5px solid $focalgrey;
      border-radius: 5px;
      padding: 0px;

      &.active {
        filter: brightness(0.5);
      }

      .label {
        color: $focalmidgrey;
        border-right: 1.5px solid $focalgrey;

        padding: 10px;
        margin-right: 0;
      }

      .icon {
        padding: 10px;
        justify-content: center;
        align-items: center;
        display: flex;
      }
    }
  }
}

.focal {
  .coming-next {
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    animation: fadeIn 200ms ease both;
    animation-iteration-count: 1;

    img,
    svg {
      height: 32px;
      width: 32px;
      margin-right: 5px;
    }
  }

  .track-item {
    .coming-next {
      margin-right: -27px;
    }

    &:hover {
      .coming-next {
        -webkit-animation-name: fadeOutToNone;
        animation-name: fadeOutToNone;
        animation: fadeOutToNone 200ms ease both;
        animation-iteration-count: 1;
      }
    }
  }
}

.focal {

  .header-trial,
  .header-login {
    font-size: 24px;
    font-weight: bold;
  }

  &.free-trial-box {
    background-color: transparent;

    .submit {
      background-color: $focalblack;
      border-radius: 5px;
    }

    .login {
      color: $focaldarkgrey;

      span {
        color: $focalblack;
        text-decoration: underline;
      }
    }

    .terms {
      color: $focaldarkgrey;

      span {
        color: $focalblack;
        text-decoration: underline;
      }
    }
  }

  &.login-modal {
    background-color: transparent;

    .forgot-password {
      color: $focalblack;
      text-decoration: underline;
    }

    .submit {
      background-color: $focalblack;
      border-radius: 5px;
    }

    .no-account {
      color: $focaldarkgrey;

      .button {
        background-color: $focalblack;
        border-radius: 5px;
      }

      span {
        color: $focalblack;
        text-decoration: underline;
      }
    }
  }

  .no-match {
    .no-match-btn {
      background-color: $focalblack;
      color: white;
      border-radius: 5px;
      padding: 15px;
      font-weight: bold;
      cursor: pointer;
      width: max-content;
    }

    .text {
      color: $focaldarkgrey;

      span {
        color: $focalblack;
        text-decoration: underline;
      }
    }

    a {
      color: $focalblack;
      text-decoration: underline;
    }
  }

  &.ErrorPage {
    min-height: 100vh;

    .button {
      background-color: $focalblack;
      border-radius: 5px;
      color: $white;
    }

    .ErrorPage__TopSection__Heading {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .ErrorPage__ContactSection {
      margin-top: 30px;
    }
  }

  .forgot-password-container {
    .btn-submit {
      background-color: $focalblack;
      border-radius: 5px;
      color: $white;
    }

    .link-login {
      color: $focaldarkgrey;

      span {
        color: $focalblack;
        text-decoration: underline;
      }
    }
  }
}

.focal {
  .samo {
    .gallery_title {
      font-family: "Poppins", sans-serif;
      font-size: 16px;
      font-weight: bold;
    }

    .gallery_subtitle {
      font-family: "Poppins", sans-serif;
      font-size: 16px;
      font-style: italic;
    }

    .gallery_date {
      font-family: "Poppins", sans-serif;
      font-size: 16px;
    }

    .gallery_donation {
      font-family: "Poppins", sans-serif;
      font-size: 16px;
    }

    .gallery_technical {
      font-family: "Poppins", sans-serif;
      font-size: 16px;
    }

    .gallery_general {
      font-family: "Poppins", sans-serif;
      font-size: 16px;
      text-align: justify;
    }

    .gallery_copyright {
      font-family: "Poppins", sans-serif;
      font-size: 12px;
    }
  }
}

.focal {
  .full-banner-image {
    width: 100%;

    img {
      width: 100%;
    }
  }

  .image-gallery-home {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 10px;

    .gallery-item {
      cursor: pointer;
      width: 100%;
      height: 300px;
      max-height: 300px;
      object-fit: contain;
      border: 1px solid;
      padding: 10px;
      background-color: transparent;

      @media only screen and (max-width: $breakpoint_880) {
        height: 160px;
      }

      @media only screen and (max-width: $breakpoint_658) {
        height: 120px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }

  .imageSliderBanner {
    margin-top: 20px;

    &.fullscreen-modal {
      margin-top: 0px;
      z-index: 1001;

      .fullscreen-btn,
      .leftBtn,
      .rightBtn {
        opacity: 0.4;

        &:hover {
          opacity: 1;
        }
      }
    }

    .leftBtn,
    .rightBtn {
      position: absolute;
      bottom: 5px;
      width: max-content;
      z-index: 2;

      .pill-button {
        margin-left: 0;
      }
    }

    .leftBtn {
      align-self: flex-start;
      left: 0;
    }

    .rightBtn {
      right: 0;
      align-self: flex-end;
    }
  }

  .marquee {
    -moz-animation: marquee 15s linear infinite;
    -webkit-animation: marquee 15s linear infinite;
    animation: marquee 15s linear infinite;
    animation-delay: 3s;
  }
}