.focal {
  .details-page {
    * {
      transition: all 200ms;
    }
  }
  .details-content-tabs {
    .tabContainer {
      overflow: hidden;
    }
    .tabs-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .save-collection-button {
        @media only screen and (min-width: $breakpoint_658) {
          display: none;
        }
      }

      .tab-indicator {
        content: "";
        display: block;
        position: relative;
        bottom: 0;
        height: 2px;
        width: calc(100% / 2);
        background: #18181a;
        margin-bottom: -2px;
        background: $focalblack;
        left: 0;
        transition: all 200ms;
        z-index: 2;
      }

      .tabs {
        display: flex;
        font-size: 18px;
        font-weight: 600;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: center;
        flex-direction: row;
        text-transform: uppercase;
        align-items: center;
        box-shadow: inset 0px -1px 0px #dcdfe4;
        max-width: 1120px;
        margin: 0px auto;
        user-select: none;
        overflow-x: auto;
        position: relative;
        z-index: 1;
        @media only screen and (max-width: $breakpoint_658) {
          justify-content: unset;
        }
        &:visited {
          text-decoration: none;
        }

        .tab {
          cursor: pointer;
          &.active {
            color: $black;
            opacity: 1;
          }
          &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
          width: 100%;
          opacity: 0.4;
          > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 15px;
            img {
              margin-bottom: 10px;
            }
            @media only screen and (max-width: $breakpoint_658) {
              padding: 15px;
              img {
                margin-bottom: 0px;
              }
            }
            @media only screen and (max-width: $breakpoint_1024) and (orientation: landscape) {
              padding: 5px;
            }
          }
        }

        a,
        div {
          text-decoration: none;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          color: $focalblack;
          position: relative;
          // -webkit-animation-name: fadeIn;
          // animation-name: fadeIn;
          // animation: fadeIn 200ms ease both;
          // animation-iteration-count: 1;

          @media only screen and (max-width: $breakpoint_880) {
            font-size: 18px;
            line-height: 22px;
          }
        }
      }
    }

    .showTab {
      display: block;
      -webkit-animation-name: slideIn;
      animation-name: slideInRight;
      animation-timing-function: ease;
      animation-iteration-count: 1;
      animation-duration: 200ms;
      &.from-left {
        animation-name: slideInLeft;
        animation-timing-function: ease;
        animation-iteration-count: 1;
        animation-duration: 200ms;
      }
    }

    .hideTab {
      display: none;
    }

    .read-tab-pane {
      &.read-tab-text {
        transition: all 1.5s cubic-bezier(0, 1, 0, 1);
        overflow: hidden;
        max-width: 1120px;
        margin: 0 auto;
        padding: 0px 120px;

        @media only screen and (max-width: $breakpoint_658) {
          padding: 0px 0px;
        }

        p {
          margin-bottom: 20px;
          line-height: 20px;
        }

        em {
          font-style: italic;
        }

        strong {
          font-weight: bold;
        }

        h3 {
          font-weight: bold;
          font-size: 24px;
          line-height: 42px;
          margin: 15px 0;
        }

        h2 {
          display: block;
          font-size: 1.5em;
          margin-top: 0.83em;
          margin-bottom: 0.83em;
          font-weight: bold;
        }

        blockquote {
          display: block;
          margin-top: 1em;
          margin-bottom: 1em;
          margin-left: 40px;
          margin-right: 40px;
        }
      }
    }
  }
  .banner-video {
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 300px;
    > div,
    iframe {
      width: 100%;
    }
  }

  .banner-cta-container {
    margin: 0 auto;
    align-items: center;
    display: flex;
    justify-content: center;
    max-width: 500px;
  }

  .generic-title {
    font-size: 30px;
    font-weight: 600;
    line-height: 38px;
    padding-bottom: 25px;
  }
  .collection-title {
    font-size: 12px;
    &.description {
      font-weight: 600;
      margin-top: 8px;
    }
  }
}
