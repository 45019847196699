.focal-showcase-wrapper {
  .default-media-card-container {
    grid-template-columns: 2fr repeat(3, minmax(0, 1fr));

    &.mobile-showcase {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-template-rows: auto auto auto;
      grid-auto-rows: 0px;
      position: relative;
      overflow: hidden;
      .media-card {
        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;
        animation: fadeIn 600ms ease both;
        animation-iteration-count: 1;
      }

      .mobile-nav-pills {
        grid-column: span 3;
        justify-content: flex-end;
      }

      .media-card:first-child {
        // height: 100%;
        grid-row: 1;
        grid-column: span 3;
        max-width: unset;
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        .images_container {
          margin-bottom: 0;
          height: 200px;
          margin-right: 10px;
        }
        .images_container,
        .text-container {
          flex: 1;
        }
        @media only screen and (max-width: $breakpoint_768) {
          grid-row: 1;
        }
        @media not all and (max-width: $breakpoint_768) {
          @supports (-webkit-appearance: none) {
            height: max-content;
          }
        }
      }
    }
    @media only screen and (max-width: $breakpoint_768) {
      grid-template-columns: 1fr repeat(2, minmax(0, 1fr));
    }
    .media-card:first-child {
      // height: 100%;
      grid-row: span 2;
      max-width: unset;
      @media only screen and (max-width: $breakpoint_768) {
        grid-row: span 3;
      }
    }
  }
}
