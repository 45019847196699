.focal {
  * {
    font-family: "Poppins", sans-serif !important;
  }
  .header-vialma {
    box-shadow: 0px 4px 10px rgba(143, 153, 170, 0.3);
    * {
      transition: all 200ms;
    }
  }
  .header-vialma,
  .menu-desktop {
    .logo-vialma {
      display: flex;
      color: $white;
      align-items: flex-end;
      span {
        margin-left: 10px;
        font-weight: bold;
        margin-bottom: 10px;
      }
    }

    .mobile-menu {
      height: unset;
      max-height: 0;
      &.expanded {
        height: unset;
        max-height: 300px;
      }
    }
    .menu-vialma .item.menu-icon {
      display: flex;
    }
  }
  .custom-toolbar {
    justify-content: space-between;
    max-width: 1140px;
    width: 100%;
    display: flex;
    margin: 0 auto;
    padding: 0 32px;
    max-height: 68px;

    .toolbar__menu-box {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .menu-items {
        text-transform: none !important;
        font-size: 0.8rem;
        font-weight: 400;
      }
      .my-collection-btn {
        cursor: pointer;
        .text {
          font-size: 0.8rem;
        }
      }
    }
    .toolbar__close {
      display: none;
      align-self: center;
    }
    .toolbar_formControl {
      .MuiOutlinedInput-root {
        font-size: 14px;
        cursor: "pointer";
      }
      .toolbar__select-loader {
        padding: 6px 15px;
        border-radius: 20px;
      }
    }

    @media (max-width: $breakpoint_658) {
      padding: 0 10px;
      .toolbar__menu-box {
        display: none;
      }
      .toolbar__close {
        display: block;
      }
    }
  }
  .toolbar-select {
    border-radius: 20px;
    .MuiSelect-select {
      padding-left: 10px;
      font-size: 0.8rem;
    }
    .MuiSelect-icon {
      display: none;
    }
    .MuiOutlinedInput-input {
      text-align: center;
      padding: 6px 15px !important;
    }
    .MuiMenu-paper {
      width: 200px;
    }
  }
  .drawer-select {
    padding: 10px 20px;
    border-radius: 20px;
    .MuiSelect-select {
      padding-left: 10px;
      height: 20px;
    }
    .MuiSelect-nativeInput {
      padding: 0;
    }
    .MuiSelect-icon {
      display: none;
    }
    .MuiOutlinedInput-input {
      text-align: center;
      padding: 0 !important;
    }
  }
}