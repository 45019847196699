.focal {
  .overlay {
    position: fixed;
    /* Sit on top of the page content */
    width: 100%;
    /* Full width (cover the whole page) */
    height: 100%;
    /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2147483008;

    &.mobile {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  .more-menu {
    &.context-menu {
      position: fixed;
      z-index: 2147483008;
      background-color: pink;
      padding: 20px 25px;
      overflow: hidden;
      transition: all 200ms;

      &.invisible {
        visibility: hidden;
      }

      &.mobile {
        width: 100%;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;

        &.from-bottom {
          -webkit-animation-name: slideInUp;
          animation-name: slideInUp;
          animation-timing-function: ease;
          animation-iteration-count: 1;
          animation-duration: 200ms;
        }
      }

      * {
        transition: all 200ms;
        font-size: 13px;
      }

      @extend .shadow;

      .context-menu-item {
        font-size: 13px;
        margin-bottom: 10px;

        &.title-role {
          cursor: default;
          opacity: 1 !important;
        }

        .icon,
        .label {
          margin-right: 10px;
          cursor: pointer;
        }

        &:hover {
          opacity: 0.5;
        }

        &:active {
          opacity: 0.1;
        }
      }

      .menu-page {
        &.showTab {
          display: block;
          -webkit-animation-name: slideIn;
          animation-name: slideInLeft;
          animation-timing-function: ease;
          animation-iteration-count: 1;
          animation-duration: 200ms;
          opacity: 1;

          &.from-left {
            animation-name: slideInRight;
            animation-timing-function: ease;
            animation-iteration-count: 1;
            animation-duration: 200ms;
          }
        }

        &.hideTab {
          display: none;
          opacity: 0;
        }

        &.user-playlists-page {
          max-height: 240px;
          overflow-y: auto;

          .context-menu-item {
            cursor: pointer;

            .icon {
              .playlist-icon {
                border-radius: 50px;
                overflow: hidden;
                width: 30px;
                height: 30px;

                &.no-border {
                  border-radius: 0;
                }
              }
            }
          }
        }

        .input {
          max-width: 250px;

          &>input,
          &>span {
            width: 100%;
          }

          &>span {
            font-style: italic;
          }
        }
      }

      &.mobile {
        .input {
          max-width: unset;
        }
      }

      @extend .thin-scrollbars
    }
  }
}