.focal-play-icon {
  cursor: pointer;
  display: flex;
  position: absolute;
  right: 10px;
  bottom: 10px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  -webkit-filter: drop-shadow(0px 5px 5px rgba(#000, 0.4));
  filter: drop-shadow(0px 5px 5px rgba(#000, 0.4));
  svg, img {
    height: 32px;
    width: auto;
  }
  .play-icon-copy {
    transition: all 200ms;
    max-width: 0px;
    overflow: hidden;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    background: $focalblack;
    color: #fff;
    padding-left: 10px;
    margin-left: -15px;
    height: 32px;
    justify-content: center;
    display: flex;
    align-items: center;
    z-index: -1;
    @media screen and (max-width: $breakpoint_768) {
      max-width: 300px;
      margin-left: -15px;
      padding-right: 10px;
      padding-left: 15px;
    }
  }
}

.focal {
  .media-card {
    &:hover {
      .play-icon-copy {
        max-width: 300px;
        margin-left: -15px;
        padding-right: 10px;
        padding-left: 15px;
      }
    }
  }
  .track-item {
    .focal-play-icon {
      opacity: 0;
      position: relative;
      right: unset;
      bottom: unset;
      &.playing {
        opacity: 1;
      }
    }
    @extend .focal-hover-shadow;
    &:hover {
      background: transparent;
      .focal-play-icon {
        opacity: 1;
      }
      .play-icon-copy {
        max-width: 300px;
        margin-left: -15px;
        padding-right: 10px;
        padding-left: 15px;
        @media screen and (max-width: $breakpoint_768) {
          margin: 0;
          padding: 0;
        }
      }
    }
    @media screen and (max-width: $breakpoint_768) {
      .focal-play-icon {
        opacity: 1;
      }
      .play-icon-copy {
        max-width: 0;
        padding: 0;
        margin: 0;
      }
      &:hover {
        .play-icon-copy {
          max-width: 0;
        }
      }
    }
  }
}
