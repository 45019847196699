$focalgrey: #F0F0F2;
$focaldarkgrey: #49494C;
$focalmidgrey: #797980;
$focalgrey: #F0F0F2;
$white: #ffffff;
$focalblack: #18181A;


// @media
$breakpoint_1400: 1400px;
$breakpoint_1200: 1200px;
$breakpoint_1024: 1024px;
$breakpoint_930: 930px;
$breakpoint_880: 880px;
$breakpoint_768: 768px;
$breakpoint_658: 658px;
$breakpoint_518: 518px;
$breakpoint_min: 1px;

//font sizes 
$size_14: 14px;
$size_15: 15px;
$size_16: 16px;
$size_18: 18px;
$size_20: 20px;
$size_24: 24px;
$size_32: 32px;
$size_40: 40px;
$size_42: 42px;

//font families
$playfair: "Playfair Display", serif;
$roboto: "Roboto", sans-serif;
$poppins: "Poppins";

$card_border_radius: 5px;
