    .filters-pill-container {
        margin: 10px 0px 10px 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .pills-active-filter {
        background: $focalblack;
    }
    .pill-filter-item {
        cursor: pointer;
        max-width: 300px;
        overflow: hidden;
        border-radius: 25px;
        background: '#fff';
        padding: 2px 12px 0px 12px;
        margin: 0px 5px 0px 5px;
        border: 1px solid $focalblack;
        height: 25px;
        @media screen and (max-width: $breakpoint_768) {
          max-width: 300px;
          padding-right: 10px;
        }
      }
    .pill-text {
        color: $focalblack;
        font-family: $poppins;
        z-index: 20;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .pill-text-selected {
        color: $white;
      }