@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");
@import "../../../styles/style.scss";
@import "focalVariables.scss";
@import "focalAnimations.scss";
@import "focalHeader.scss";
@import "focalBanner.scss";
@import "focalComponents.scss";
@import "focalPlayIcon.scss";
@import "focalPlayer.scss";
@import "focalShowcase.scss";
@import "focalDetailsPage.scss";
@import "focalContextMenu.scss";
@import "focalCollectionModal.scss";
@import "filterPills.scss";
